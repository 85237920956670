import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentTooltip.figmaUrl.ios} codeUrl={checklists.componentTooltip.codeUrl.ios} checklists={checklists.componentTooltip.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Tooltip displays informative text when users hover, focus, or click an element.`}</p>
    <p>{`Use Legion Tooltip styles allow users to type text into an app, with support lots type and attribute to customize.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`    import LegionUI
`}</code></pre>
    <div className="divi" />
    <h2>{`Variant`}</h2>
    <p>{`LGNTooltip with the following appearances:`}</p>
    <ol>
      <li parentName="ol">{`Tooltip`}</li>
      <li parentName="ol">{`Tooltip icon`}</li>
      <li parentName="ol">{`Tooltip + arrow position: no arrow, bottom center, bottom left, bottom right, top center, left, right`}</li>
      <li parentName="ol">{`Tooltip icon + arrow position: no arrow, left, right, bottom, top left, top right, top arrow, top no arrow`}</li>
    </ol>
    <p>{`You can change the Tooltip description with title prop`}</p>
    <undefined><div {...{
        "className": "wrapper-display-component-three",
        "style": {
          "backgroundColor": "white"
        }
      }}>{`
  `}<div parentName="div" {...{
          "className": "item-three"
        }}>{`
    `}<div parentName="div" {...{
            "style": {
              "textAlign": "left"
            }
          }}>{`Tooltip`}</div>{`
    `}<div parentName="div" {...{
            "style": {
              "padding": "7px 0"
            }
          }}>{`
      `}<img parentName="div" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/v1.png"
            }}></img>{`
    `}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
          "className": "item-three"
        }}>{`
    `}<div parentName="div" {...{
            "style": {
              "textAlign": "left"
            }
          }}>{`Tooltip icon`}</div>{`
    `}<div parentName="div" {...{
            "style": {
              "padding": "7px 0"
            }
          }}>{`
      `}<img parentName="div" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/v2.png"
            }}></img>{`
    `}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
          "className": "item-three"
        }}>{`
    `}<div parentName="div" {...{
            "style": {
              "textAlign": "left"
            }
          }}>{`Tooltip + arrow position`}</div>{`
    `}<div parentName="div" {...{
            "style": {
              "padding": "7px 0"
            }
          }}>{`
      `}<img parentName="div" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/v3.png"
            }}></img>{`
    `}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
          "className": "item-three"
        }}>{`
    `}<div parentName="div" {...{
            "style": {
              "textAlign": "left"
            }
          }}>{`Tooltip icon + arrow position`}</div>{`
    `}<div parentName="div" {...{
            "style": {
              "padding": "7px 0"
            }
          }}>{`
      `}<img parentName="div" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/v4.png"
            }}></img>{`
    `}</div>{`
  `}</div>
      </div>{`
Use this Function : LGNTooltip for Tooltip & LGNTooltipIcon for Tooltip Icon`}</undefined>
    <p>{`This code sample demonstrates how to modify the variant of Tooltip :`}</p>
    <p><strong parentName="p">{` A. Tooltip `}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNTooltipBase(text: "This is a tooltip", tipPosition: .noTip)
`}</code></pre>
    <p>{`As view modifier.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State private var isShowing: = false
var body: some View {
    VStack {
        LGNOutlineBtn(title: "Tap to show tooltip", {
            isShowing.toggle()
        })
        .variant(size: .normal, responsive: false)
        .lgnTooltip($isShowing, text: "this is tooltip", position: .noTip)

        Text("Always shown")
        .lgnTooltip(text: "this tooltip is always shown", position: .noTip)
    }
}
`}</code></pre>
    <p><strong parentName="p">{` B. Tooltip Icon `}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNTooltipIcon(text: "This is a tooltip", position: .noTip)
`}</code></pre>
    <p><strong parentName="p">{` C. Tooltip + arrow position `}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNTooltipBase(text: "This is a tooltip")
`}</code></pre>
    <p>{`As view modifier.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State private var isShowing: = false
var body: some View {
    VStack {
        LGNOutlineBtn(title: "Tap to show tooltip", {
            isShowing.toggle()
        })
        .variant(size: .normal, responsive: false)
        .lgnTooltip($isShowing, text: "this is tooltip")

        Text("Always shown")
        .lgnTooltip(text: "this tooltip is always shown")
    }
}
`}</code></pre>
    <p><strong parentName="p">{` D. Tooltip icon + arrow position `}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNTooltipIcon(text: "This is a tooltip")
`}</code></pre>
    <div className="divi" />
    <h2>{`Attribute`}</h2>
    <p>{`Legion Have 1 Attribute for Customize Tooltip :`}</p>
    <h3><strong parentName="h3">{` Position `}</strong></h3>
    <p>{`This position attribute for user to add arrow position on the tooltip`}</p>
    <div {...{
      "style": {
        "display": "flex",
        "justifyContent": "center"
      }
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/position.png"
      }}></img>
    </div>
    <h3><strong parentName="h3">{`Bottom Center`}</strong></h3>
    <p>{`This code sample demonstrates how to modify the type of Tooltip :`}</p>
    <div {...{
      "className": "wrapper-display-component-three",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/bc1.png"
          }}></img>{`
    `}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Tooltip`}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/bc2.png"
          }}></img>{`
    `}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Tooltip Icon`}</div>{`
  `}</div>
    </div>
    <p><strong parentName="p">{` Tooltip `}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNTooltipBase(text: "This is a tooltip")
`}</code></pre>
    <p>{`As View modifier.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State private var isShowing: = false
var body: some View {
    VStack {
        LGNOutlineBtn(title: "Tap to show tooltip", {
            isShowing.toggle()
        })
        .variant(size: .normal, responsive: false)
        .lgnTooltip($isShowing, text: "this is tooltip")

        Text("Always shown")
        .lgnTooltip(text: "this tooltip is always shown")
    }
}
`}</code></pre>
    <p><strong parentName="p">{` Tooltip Icon `}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNTooltipIcon(text: "This is a tooltip")
`}</code></pre>
    <h3><strong parentName="h3">{`Bottom Left`}</strong></h3>
    <p>{`This code sample demonstrates how to modify the type of Tooltip :`}</p>
    <div {...{
      "className": "wrapper-display-component-three",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/bl1.png"
          }}></img>{`
    `}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Tooltip`}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/bl2.png"
          }}></img>{`
    `}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Tooltip Icon`}</div>{`
  `}</div>
    </div>
    <p><strong parentName="p">{` Tooltip `}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNTooltipBase(text: "This is a tooltip", tipPosition: .bottomLeft)
`}</code></pre>
    <p>{`As View modifier.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State private var isShowing: = false
var body: some View {
    VStack {
        LGNOutlineBtn(title: "Tap to show tooltip", {
            isShowing.toggle()
        })
        .variant(size: .normal, responsive: false)
        .lgnTooltip($isShowing, text: "this is tooltip", position: .topRight)

        Text("Always shown")
        .lgnTooltip(text: "this tooltip is always shown", position: .topRight)
    }
}
`}</code></pre>
    <p><strong parentName="p">{` Tooltip Icon `}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNTooltipIcon(text: "This is a tooltip", position: .topLeft)
`}</code></pre>
    <h3><strong parentName="h3">{`Bottom Right`}</strong></h3>
    <p>{`This code sample demonstrates how to modify the type of Tooltip :`}</p>
    <div {...{
      "className": "wrapper-display-component-three",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/br1.png"
          }}></img>{`
    `}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Tooltip`}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/br2.png"
          }}></img>{`
    `}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Tooltip Icon`}</div>{`
  `}</div>
    </div>
    <p><strong parentName="p">{` Tooltip `}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNTooltipBase(text: "This is a tooltip", tipPosition: .bottomRight)
`}</code></pre>
    <p>{`As View modifier.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State private var isShowing: = false
var body: some View {
    VStack {
        LGNOutlineBtn(title: "Tap to show tooltip", {
            isShowing.toggle()
        })
        .variant(size: .normal, responsive: false)
        .lgnTooltip($isShowing, text: "this is tooltip", position: .topLeft)

        Text("Always shown")
        .lgnTooltip(text: "this tooltip is always shown", position: .topLeft)
    }
}
`}</code></pre>
    <p><strong parentName="p">{` Tooltip Icon `}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNTooltipIcon(text: "This is a tooltip", position: .topRight)
`}</code></pre>
    <h3><strong parentName="h3">{`Top Center`}</strong></h3>
    <p>{`This code sample demonstrates how to modify the type of Tooltip :`}</p>
    <div {...{
      "className": "wrapper-display-component-three",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/tp1.png"
          }}></img>{`
    `}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Tooltip`}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/tp2.png"
          }}></img>{`
    `}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Tooltip Icon`}</div>{`
  `}</div>
    </div>
    <p><strong parentName="p">{` Tooltip `}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNTooltipBase(text: "This is a tooltip", tipPosition: .top)
`}</code></pre>
    <p>{`As View modifier.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State private var isShowing: = false
var body: some View {
    VStack {
        LGNOutlineBtn(title: "Tap to show tooltip", {
            isShowing.toggle()
        })
        .variant(size: .normal, responsive: false)
        .lgnTooltip($isShowing, text: "this is tooltip", position: .bottom)

        Text("Always shown")
        .lgnTooltip(text: "this tooltip is always shown", position: .bottom)
    }
}
`}</code></pre>
    <p><strong parentName="p">{` Tooltip Icon `}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNTooltipIcon(text: "This is a tooltip", position: .bottom)
`}</code></pre>
    <h3><strong parentName="h3">{`Left`}</strong></h3>
    <p>{`This code sample demonstrates how to modify the type of Tooltip :`}</p>
    <div {...{
      "className": "wrapper-display-component-three",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/l1.png"
          }}></img>{`
    `}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Tooltip`}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/l2.png"
          }}></img>{`
    `}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Tooltip Icon`}</div>{`
  `}</div>
    </div>
    <p><strong parentName="p">{` Tooltip `}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNTooltipBase(text: "This is a tooltip", tipPosition: .left)
`}</code></pre>
    <p>{`As View modifier.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State private var isShowing: = false
var body: some View {
    VStack {
        LGNOutlineBtn(title: "Tap to show tooltip", {
            isShowing.toggle()
        })
        .variant(size: .normal, responsive: false)
        .lgnTooltip($isShowing, text: "this is tooltip", position: .right)

        Text("Always shown")
        .lgnTooltip(text: "this tooltip is always shown", position: .right)
    }
}
`}</code></pre>
    <p><strong parentName="p">{` Tooltip Icon `}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNTooltipIcon(text: "This is a tooltip", position: .left)
`}</code></pre>
    <h3><strong parentName="h3">{`Right`}</strong></h3>
    <p>{`This code sample demonstrates how to modify the type of Tooltip :`}</p>
    <div {...{
      "className": "wrapper-display-component-three",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/r1.png"
          }}></img>{`
    `}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Tooltip`}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/r2.png"
          }}></img>{`
    `}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Tooltip Icon`}</div>{`
  `}</div>
    </div>
    <p><strong parentName="p">{` Tooltip `}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNTooltipBase(text: "This is a tooltip", tipPosition: .right)
`}</code></pre>
    <p>{`As View modifier.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State private var isShowing: = false
var body: some View {
    VStack {
        LGNOutlineBtn(title: "Tap to show tooltip", {
            isShowing.toggle()
        })
        .variant(size: .normal, responsive: false)
        .lgnTooltip($isShowing, text: "this is tooltip", position: .left)

        Text("Always shown")
        .lgnTooltip(text: "this tooltip is always shown", position: .left)
    }
}
`}</code></pre>
    <p><strong parentName="p">{` Tooltip Icon `}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNTooltipIcon(text: "This is a tooltip", position: .right)
`}</code></pre>
    <div className="divi" />
    <h2>{`Properties`}</h2>
    <h3><strong parentName="h3">{` LGNTooltipBase properties `}</strong></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Property`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Description`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Default Value`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the main text you want to display on tooltip`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`tipPosition`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`arrow location related to tooltip body. `}<inlineCode parentName="td">{`.noTip`}</inlineCode>{`, `}<inlineCode parentName="td">{`.bottom`}</inlineCode>{`, `}<inlineCode parentName="td">{`.bottomLeft`}</inlineCode>{`, `}<inlineCode parentName="td">{`.bottomRight`}</inlineCode>{`, `}<inlineCode parentName="td">{`.top`}</inlineCode>{`, `}<inlineCode parentName="td">{`.left`}</inlineCode>{`, and `}<inlineCode parentName="td">{`.right`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bottom`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3><strong parentName="h3">{` lgnTooltip view modifier function properties `}</strong></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Property`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Description`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Default Value`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`enabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`receive your boolean binding variable to decide whether or not the tooltip shall appear`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.constant(true)`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the main text you want to display on tooltip`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`position`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`tooltip location related to parent view. `}<inlineCode parentName="td">{`.noTip`}</inlineCode>{`, `}<inlineCode parentName="td">{`.bottom`}</inlineCode>{`, `}<inlineCode parentName="td">{`.bottomLeft`}</inlineCode>{`, `}<inlineCode parentName="td">{`.bottomRight`}</inlineCode>{`, `}<inlineCode parentName="td">{`.top`}</inlineCode>{`, `}<inlineCode parentName="td">{`.left`}</inlineCode>{`, and `}<inlineCode parentName="td">{`.right`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.top`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`maxWidth`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`maximum width of tooltip body`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`500`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`maxHeight`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`maximum height of tooltip body`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`500`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3><strong parentName="h3">{` LGNTooltipIcon properties `}</strong></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`icon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the icon you want the tooltip will point on`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Image(systemName: "questionmark.circle")`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`isTooltipShown`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`receive your boolean binding variable to decide whether or not the tooltip shall appear`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.constant(true)`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`maxHeight`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`maximum height of tooltip body`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`400`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`maxWidth`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`maximum width of tooltip body`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`400`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`position`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`tooltip location related to icon view. `}<inlineCode parentName="td">{`.noTip`}</inlineCode>{`, `}<inlineCode parentName="td">{`.bottom`}</inlineCode>{`, `}<inlineCode parentName="td">{`.bottomLeft`}</inlineCode>{`, `}<inlineCode parentName="td">{`.bottomRight`}</inlineCode>{`, `}<inlineCode parentName="td">{`.top`}</inlineCode>{`, `}<inlineCode parentName="td">{`.left`}</inlineCode>{`, and `}<inlineCode parentName="td">{`.right`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.top`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the main text you want to display on tooltip`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
      </tbody>
    </table>
    <div className="divi" />
    <h2>{`Example Project`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNTooltipBase(
    text: "Text to be shown inside tooltip",
    tipPosition: .left
)
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`Text("I use view modifier")
    .lgnTooltip(
        $shouldShowTooltip, // your boolean state
        text: "Text to be shown inside tooltip",
        position: .topLeft,
        maxWidth: 400,
        maxHeight: 400
    )
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNTooltipIcon(
    $shouldShowTooltip, // your boolean state
    icon: Image(systemName: "questionmark.circle"),
    text: "Text to be shown inside tooltip",
    position: .top,
    maxWidth: 400,
    maxHeight: 400
) { isShown in
    print("Icon is tapped, and tooltip shown is \\(isShown)")
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      